import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {Component} from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../index'

library.add(faArrowRight)
library.add(faArrowLeft)

class About extends Component {
  render() {
    return(
      <div className={'about'}>
        <Header name={'About Riku'}/>
        <Link to ={'/profile'}><FontAwesomeIcon icon={'arrow-left'} className={'about-arrow'} /></Link>
        <div className={'content'}>
          <div className={'about-header-img'} />
          <div className={'about-profile'}>
            <div className={'name-bd'}>
              <h2>Riku Tahko</h2>
              <p>November 1st 1984</p>
            </div>
            <button>Receive Newsletter</button>
          </div>
          <div className={'about-info'}>
            Racecar driver in FIA European Rallycross
            Championship/ BMW I Brand Ambassador /
            Monster Energy athlete #motorsport4life
          </div>
          <div className={'about-info'}>
            <h3>Awards</h3>
            <ul>
              <li>Finnish rally championship bronze 2012</li>
              <li>FIA Winter cup 4th 2007</li>
              <li>National rally winner 2006</li>
              <li>WRC Rally Finland best novice 2005</li>
            </ul>
          </div>
          <div className={'about-info'}>
            <h3>Sponsors</h3>
            Monster Energy, MINI, Odyssey Battery, Gloryfy,
            Specialized, Innocate, Asfalttikulma, ST Motors,
          </div>
        </div>
      </div>
    )
  }
}

export default About