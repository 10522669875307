import React, { Component } from 'react'

import { Header } from '../index'

class Sponsors extends Component {
  render() {
    return (
      <div className={'sponsors'}>
        <Header name={'Sponsors'} />
        <div className={'content'}>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/ramirent.png'} alt={'ramirent'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/bmw.png'} alt={'bmw'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/duolog.png'} alt={'duolog'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/evgraphics.png'} alt={'evgraphics'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/innocate.png'} alt={'innocate'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image blackBackground'}>
              <img src={'sponsors/monster.png'} alt={'monster'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/ponkestheshop.png'} alt={'Ponke\'s The Shop'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/stmotors.png'} alt={'stmotors'} />
            </div>
            <a>20% OFF</a>
          </div>
          <div className={'sponsor'}>
            <div className={'sponsor-image'}>
              <img src={'sponsors/tahko.png'} alt={'tahko'} />
            </div>
            <a>20% OFF</a>
          </div>
        </div>
      </div>
    )
  }
}

export default Sponsors