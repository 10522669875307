import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../index'

library.add(faArrowRight)
library.add(faArrowLeft)

class Services extends Component {
  render() {
    return(
      <div className={'about'}>
        <Header name={'Services'} />
        <Link to ={'/profile'}><FontAwesomeIcon icon={'arrow-left'} className={'about-arrow'} /></Link>
        <div className={'content'}>
          <div className={'about-header-img'}></div>
          <div className={'about-info'}>
            <h3>Header</h3>
              Text here
          </div>
        </div>
      </div>
    );
  }
}

export default Services