import moment from 'moment'
import React, { Component } from 'react'

class SendQuote extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      phone: '',
      email: '',
      drivetrain: 'FWD',
      dateWishes: moment().format('YYYY-MM-DD'),
      openWord: ''
    }
  }

  render() {
    const { name, phone, email, drivetrain, dateWishes, openWord } = this.state

    return (
      <div className={'contact-container'}>
        <label>
          Name
          <input value={name} onChange={(e) => this.setState({ name: e.target.value })} />
        </label>
        <label>
          Phone
          <input value={phone} onChange={(e) => this.setState({ phone: e.target.value })} />
        </label>
        <label>
          Email
          <input value={email} onChange={(e) => this.setState({ email: e.target.value })} />
        </label>
        <label>
          Would you like to drive
          <select value={drivetrain} onChange={(e) => this.setState({ drivetrain: e.target.value })}>
            <option value={'FWD'}>FWD</option>
            <option value={'RWD'}>RWD</option>
            <option value={'AWD'}>AWD</option>
          </select>
        </label>
        <label>
          Date Wishes
          <input type={'date'} value={dateWishes} onChange={(e) => this.setState({ dateWishes: e.target.value })} />
        </label>
        <label>
          Open word
          <textarea value={openWord} onChange={(e) => this.setState({ openWord: e.target.value })} />
        </label>

        <div className={'contact-send-container'}>
          <a href={`mailto:riku.tahko@gmail.com?subject=Quote Request&body=Name:%20${name.replace(/ /g, '%20')}%0D%0APhone:%20${phone.replace(/ /g, '%20')}%0D%0AEmail:%20${email}%0D%0A%0D%0APreferred%20drivetrain:%20${drivetrain}%0D%0ADate%20Wishes:%20:${dateWishes}%0D%0A%0D%0AOpen%20Word:%0D%0A${openWord.replace(/\n/g, '%0D%0A').replace(/ /g, '%20')}`}>
            SEND EMAIL
          </a>
        </div>
      </div>
    )
  }
}

export default SendQuote