import moment from 'moment'
import React, { Component } from 'react'
import AddToCalendar from 'react-add-to-calendar'

import { Header } from '../index'

class Calendar extends Component {
  render() {
    return(
      <div>
        <Header name={'Calendar'}/>
        <div className={'calendar'}>
          {
            events.map((event) => (
              <Event event={event} />
            ))
          }
        </div>
      </div>
    )
  }
}

export default Calendar

const Event = ({ event }) => (
  <div className={'calendar-item'}>
    <div className={'date'}>{moment(event.startTime).isSame(moment(), 'day') ? 'Today - ' : ''}{moment(event.startTime).format('ddd D MMM')}</div>
    <div className={'event'}>
      <div className={'time'}>
        <p className={'start-time'}>{moment(event.startTime).format('HH:mm')}</p>
        <p className={'end-time'}>{moment(event.endTime).format('HH:mm')}</p>
      </div>
      <div className={'event-name'}>
        <h3>{event.title}</h3>
        {event.location}
      </div>
      <AddToCalendar
        event={event}
        rootClass={'addToCalendarRoot'}
        buttonClassClosed={'addToCalendar'}
        dropdownClass={'addToCalendarDropdown'}
        buttonLabel={'Add to calendar'}
      />
    </div>
  </div>
)

const events = [
  {
    title: 'Rallycross Festival Kouvola',
    description: 'Finnish championship',
    location: 'Kouvola',
    startTime: '2018-11-01T14:00:00+02:00',
    endTime: '2018-11-01T20:00:00+02:00',
  },
  {
    title: 'Race car in Espoo',
    description: 'Riku is showing his race car in Espoo',
    location: 'Espoo',
    startTime: '2018-12-10T13:00:00+02:00',
    endTime: '2018-12-10T15:00:00+02:00',
  }
]