import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'

import { Header } from '../index'
import About from './About'
import References from './References'
import SendQuote from './SendQuote'
import Services from './Services'

library.add(faArrowRight)
library.add(faArrowLeft)

class ProfileRouter extends Component {
  render() {
    return (
        <Router>
          <div className={'content'}>
            <Header name={window.location.pathname.indexOf('/references') >= 0 ? 'References' : window.location.pathname.indexOf('/contact') >= 0 ? 'Send Quote' : 'Profile'} />
            <Route exact path={'/profile'} component={Profile} />
            <Route path={'/profile/about'} component={About} />
            <Route path={'/profile/services'} component={Services} />
            <Route path={'/profile/references'} component={References} />
            <Route path={'/profile/contact'} component={SendQuote} />
          </div>
        </Router>
    )
  }
}

export default ProfileRouter

class Profile extends React.Component {
  render() {
    return (
      <div>
        <div className={'profile'}>
          <Link to={'/profile/about'}><FontAwesomeIcon icon={'arrow-right'} className={'profile-arrow'} /></Link>
            <div className={'profile-img'}>
              <div />
            </div>
            <h1>Riku Tahko</h1>
            <p>Racecar driver</p>
        </div>
        <div className={'driving-school'}>
          <Link to={'/profile/contact'}><FontAwesomeIcon icon={'arrow-right'} className={'profile-arrow school-arrow'} /></Link>
          <h2>RTRC</h2>
          <div className={'rtrc-container'}>
            <Link to={'/profile/contact'}>
              <div className={'rtrc-link'}>
                <div className={'shader consulting'} />
                <p>Riku Tahko Rally Consulting</p>
              </div>
            </Link>
            <Link to={'/profile/references'}>
              <div className={'rtrc-link'}>
                <div className={'shader references'} />
                <p>References</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
