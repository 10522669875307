import React, { Component } from 'react'

class References extends Component {
  render() {
    return (
      <div>
        <SimonGalatariotis />
        <NicholasLatifi />
        <ToomasHeikkinen />
        <MikoMarczyk />
        {/* <QuentinGilbert /> */}
      </div>
    )
  }
}

export default References

const SimonGalatariotis = () => (
  <div className={'references-container'}>
    <div className={'references-image-nicholas'} />
    <h1 className={'references-name'}>Simon Galatariotis</h1>
    <p className={'references-title'}>Skoda R5</p>
    <p className={'references-title'}>Cyprus Rally Champion</p>
    <p className={'references-quote'}>”With Riku I found many small things into my driving, Riku has also helped us a lot to find an ideal set up for different conditions"</p>
  </div>
)

const NicholasLatifi = () => (
  <div className={'references-container'}>
    <div className={'references-image-nicholas'} />
    <h1 className={'references-name'}>Nicholas Latifi</h1>
    <p className={'references-title'}>Force India F1 / DAMS F2</p>
    <p className={'references-title'}>F2 race winner</p>
    <p className={'references-quote'}>"Working with Riku is always an enjoyable and informative experience! He has helped me a lot of fundamental driving skills in conditions that I’m not really used to."</p>
  </div>
)

const ToomasHeikkinen = () => (
  <div className={'references-container'}>
    <div className={'references-image-toomas'} />
    <h1 className={'references-name'}>Toomas Heikkinen</h1>
    <p className={'references-title'}>MJP Racing GRC Europe</p>
    <p className={'references-title'}>GRC Champion, Vice World Rallycross Champion</p>
    <p className={'references-quote'}>"Without Riku, I would have never learned 4WD driving techniques so quickly. Riku has touch to improve amateur and professional drivers in a really efficient way."</p>
  </div>
)

const MikoMarczyk = () => (
  <div className={'references-container'}>
    <div className={'references-image-miko'} />
    <h1 className={'references-name'}>Miko Marczyk</h1>
    <p className={'references-title'}>Skoda R5</p>
    <p className={'references-title'}>Skoda Motorsport Poland</p>
    <p className={'references-quote'}>"I had really limited experience on gravel rallies. after spending a day with Riku I found a lot of speed and confidence."</p>
  </div>
)

const QuentinGilbert = () => (
  <div className={'references-container'}>
    <div className={'references-image-quentin'} />
    <h1 className={'references-name'}>Quentin Gilbert</h1>
    <p className={'references-title'}>Skoda R5</p>
    <p className={'references-title'}>WRC2 / French Championship</p>
    <p className={'references-quote'}> "A very good expérience to progress"</p>
  </div>
)