import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'

import { Header } from '../index'

library.add(faBell)
library.add(faQuoteLeft)
library.add(faQuestionCircle)

class Settings extends Component {
  render() {
    return(
      <div className={'settings'}>
        <Header name={'Settings'} />
        <div className={'content'}>
          <div className={'setting'}>
              <h3><FontAwesomeIcon icon={'bell'} /> Notifications</h3>
              <p>All</p>
          </div>
          <div className={'setting'}>
            <h3><FontAwesomeIcon icon={'question-circle'} /> Help</h3>
            <p>Questions?</p>
          </div>
          <div className={'setting'}>
              <h3><FontAwesomeIcon icon={'quote-left'} /> Feedback</h3>
              <p>Comment?</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings