import './index.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'

import Calendar from './components/Calendar'
import Profile from './components/Profile'
import Settings from './components/Settings'
import Social from './components/Social'
import Sponsors from './components/Sponsors'

library.add(faStroopwafel)
library.add(faStar)
library.add(faUser)
library.add(faCalendarAlt)
library.add(faCog)
library.add(faTable)
library.add(faNewspaper)

export class Header extends Component {
  render() {
    return (
      <div className={'header'}>
        <h2>{this.props.name}</h2>
      </div>
    )
  }
}

class Navigation extends Component {
  render() {
    return(
      <div className={'navigation'}>
        <Link to={'/'} className={window.location.pathname === '/' ? 'active' : ''}><FontAwesomeIcon icon='newspaper' /></Link>
        <Link to={'/calendar'} className={window.location.pathname === '/calendar' ? 'active' : ''}><FontAwesomeIcon icon={'calendar-alt'} /></Link>
        <Link to={'/sponsors'} className={window.location.pathname === '/sponsors' ? 'active' : ''}><FontAwesomeIcon icon={'star'} /><div className={'notification'}></div></Link>
        <Link to={'/profile'} className={window.location.pathname === '/profile' ? 'active' : ''}><FontAwesomeIcon icon={'user'} /></Link>
        <Link to={'/settings'} className={window.location.pathname === '/settings' ? 'active' : ''}><FontAwesomeIcon icon={'cog'} /></Link>
      </div>
    )
  }
}

class Content extends Component {
  componentDidMount = () => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test( userAgent )
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)

    if (isIos() && !isInStandaloneMode()) {
      this.setState({ showInstallMessage: true })
    }
  }

  render() {
    return(
      <Router>
        <div className={'router'}>
          <Navigation/>
          <Route exact path={'/(|facebook|instagram|youtube)'} component={Social} />
          <Route exact path={'/calendar'} component={Calendar} />
          <Route exact path={'/sponsors'} component={Sponsors} />
          <Route path={'/profile'} component={Profile} />
          <Route exact path={'/settings'} component={Settings} />
        </div>
      </Router>
    )
  }
}

ReactDOM.render(
  <Content />,
  document.getElementById('root')
)
