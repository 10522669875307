import React, { Component } from 'react'
import { FacebookProvider, Page } from 'react-facebook'
import InstagramEmbed from 'react-instagram-embed'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
import { Timeline } from 'react-twitter-widgets'
import YouTube from 'react-youtube'

import { Header } from '../index'

class Social extends Component {
  constructor(props) {
    super(props)

    this.state = {
      randomNumber: Math.round(Math.random()*Date.now())
    }
  }
  forceToRender = () => {
    let randomNumber = Math.round(Math.random()*Date.now())
    while (randomNumber === this.state.randomNumber) {
      randomNumber = Math.round(Math.random()*Date.now())
    }

    return this.setState({ randomNumber })
  }

  render() {
    return (
      <div className={'social'}>
        <Header name={'Riku in action'} />
        <Router>
          <div className={'content'}>
            <div className={'socialnav'}>
              <Link to={'/'} onClick={this.forceToRender} className={window.location.pathname === '/' ? 'socialnavLink active' : 'socialnavLink'}>Twitter</Link>
              <Link to={'/facebook'} onClick={this.forceToRender} className={window.location.pathname === '/facebook' ? 'socialnavLink active' : 'socialnavLink'}>Facebook</Link>
              <Link to={'/instagram'} onClick={this.forceToRender} className={window.location.pathname === '/instagram' ? 'socialnavLink active' : 'socialnavLink'}>Instagram</Link>
              <Link to={'/youtube'} onClick={this.forceToRender} className={window.location.pathname === '/youtube' ? 'socialnavLink active' : 'socialnavLink'}>Youtube</Link>
            </div>
            <Route exact path={'/'} component={Twitter} />
            <Route exact path={'/facebook'} component={Facebook} />
            <Route exact path={'/instagram'} component={Instagram} />
            <Route exact path={'/youtube'} component={Youtube} />
          </div>
        </Router>
      </div>
    )
  }
}

export default Social

const Twitter = () => (
  <div id={'twitter'}>
    <Timeline
      dataSource={{
        sourceType: 'profile',
        screenName: 'rikutahko'
      }}
      options={{
        username: 'rikutahko',
        height: '100%',
        theme: 'dark',
        chrome: 'nofooter transparent noborders'
      }}
    />
  </div>
)

const Facebook = () => (
  <div id={'twitter'}>
    <FacebookProvider appId={'189234021988672'}>
      <Page
        href={'https://www.facebook.com/rikutahkorally/'}
        tabs={'timeline'}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        width={'500'}
        adaptContainerWidth={true}
      />
    </FacebookProvider>
  </div>
)

class Instagram extends React.Component {
  render() {
    return(
      <div id={'twitter'}>
        <InstagramEmbed
          url={'https://www.instagram.com/p/BpHJGXghktv/'}
          maxWidth={500}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
        />
      </div>
    );
  }
}

class Youtube extends React.Component {
  render() {
    return(
      <div id={'twitter'}>
        <YouTube
          videoId={'5Zhdb8ING30'}
          opts={{
            height: '100%',
            width: '100%'
          }}
          onReady={this._onReady}
        />
      </div>
    );
  }
}
